import React from 'react';
import { Form, Input, Button, Select, DatePicker, Space, Dropdown, message } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;
// 搜索条件
const SearchCriteria = (data) => {

    const [form] = Form.useForm();

    // 生成所有搜索条件
    const createSeacrhs = () => {
        const children = [];
        for (let i = 0; i < data.seacrhItems.length; i++) {
            children.push(createSeacrhItem(data.seacrhItems[i]));
        }
        return children;
    };

    // 搜索条件items
    const createSeacrhItem = (itemData) => {
        // input
        if (itemData.inputType === 'input') {
            return (
                <Form.Item
                    name={itemData.seacrhType}
                    label={itemData.title}
                    key={new Date().valueOf()}
                    className='searchCriteria-item'
                >
                    <Search
                        placeholder={'请输入' + itemData.title}
                        onSearch={itemSeacrh()}
                    />
                </Form.Item>)
        }
        // select
        else if (itemData.inputType === 'select') {
            return (
                <Form.Item
                    name={itemData.seacrhType}
                    label={itemData.title}
                    key={new Date().valueOf()}
                    className='searchCriteria-item'
                >
                    <Select style={{ width: '100%' }}
                        onChange={selectChange}
                    >
                        {
                            createSelectOption(itemData.children)
                        }
                    </Select>
                </Form.Item>
            )
        }
        // date_range
        else if (itemData.inputType === 'date_range') {
            return (
                <Form.Item
                    name={itemData.seacrhType}
                    label={itemData.title}
                    key={new Date().valueOf()}
                    className='searchCriteria-item'
                >
                    <RangePicker style={{ width: '100%' }} onChange={dateRangeChange} />
                </Form.Item>
            )
        }
        // buttons
        else if (itemData.inputType === 'buttons') {
            return createSeacrhButtons(itemData.children)
        }
    }

    // 搜索条件select中option
    const createSelectOption = (selectData) => {
        // 函数体
        const options = [];
        for (let j = 0; j < selectData.length; j++) {
            options.push(
                <Option key={selectData[j].value} value={selectData[j].value}>{selectData[j].title}</Option>
            )
        }
        return options;
    }

    // select改变事件
    const selectChange = (value) => {
        onFinish()
    }

    // date_range改变事件
    const dateRangeChange = (value, valueStr) => {
        onFinish()
    }

    // 搜索条件-按钮
    const createSeacrhButtons = (data) => {
        return (<div key={new Date().valueOf()}>
            {
                data.map((dataObj) => {
                    if (dataObj.key === 'submit') {
                        return <Button type="primary" style={{ marginBottom: 24, display: 'none' }} key={dataObj.key} className='searchCriteria-item-button' htmlType="submit">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'add') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={addData} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'addImport') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={addImportFun} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'delete') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={deleteData} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'export') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={exportData} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'batchShare') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={batchShare} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'downloadfile') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={downloadFileFun} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else if (dataObj.key === 'batchDownload') {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} onClick={batchDownloadFun} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                    else {
                        return <Button type="primary" style={{ marginBottom: 24 }} key={dataObj.key} className='searchCriteria-item-button' htmlType="button">{dataObj.title}</Button>
                    }
                })
            }
        </div>)
    }

    const itemSeacrh = () => {
        return () => {
            onFinish()
        }
    }

    // 新增
    const addData = () => {
        data.addFun();
    }

    /**
     * 批量导入
     */
    const addImportFun = () => {
        data.addImportFun();
    }

    // 删除
    const deleteData = () => {
        data.deleteFun();
    }

    const batchShare = () => {
        data.batchShareFun();
    }

    // 导出
    const exportData = () => {
        data.exportFun()
    }

    // 表单提交-搜索
    const onFinish = () => {
        data.seacrhFun();
    };

    /**
     * 下载创建模板
     */
    const downloadFileFun = () => {
        data.downloadFileFun();
    }

    const batchDownloadFun = () => {
        data.batchDownloadFun();
    }

    // 字段值更新时触发回调事件
    const onValuesChangeFun = (formItemData, formData) => {
        data.updateSeacrhValue(formData)
    }


    return (
        <Form
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form searchCriteria-seacrh-form"
            onFinish={onFinish}
            initialValues={data.initialValueParam}
            onValuesChange={onValuesChangeFun}
        >
            <Space size={0} wrap>
                {createSeacrhs()}
            </Space>
        </Form>
    );
};

export default SearchCriteria;